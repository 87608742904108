import * as $ from "jquery";
import * as Highcharts from 'highcharts';

$(document).on("ready, turbolinks:load", function() {

  /**
   *
   *
   * usage:
   *  <div data-sparkline="[{name: 'Vacate', color: '#F19E53', data: [3]}]"></div>
   *  [{"name":"Vacate","color":"#F19E53","data":[417]},{"name":"Renew","color":"#A3EDBA","data":[586]},{"name":"Undecided","color":"#8087E8","data":[262]}]
   */


  /**
   * Create a constructor for sparklines that takes some sensible defaults and merges in the individual
   * chart options. This function is also available from the jQuery plugin as $(element).highcharts('SparkLine').
   */
  Highcharts.SparkLine = function (a, b, c) {
    let hasRenderToArg = typeof a === 'string' || a.nodeName,
        options = arguments[hasRenderToArg ? 1 : 0],
        defaultOptions = {
          colors: ['#8087E8', '#A3EDBA', '#F19E53', '#6699A1', '#E1D369', '#87B4E7', '#DA6D85', '#BBBAC5'],
          chart: {
            renderTo: (options.chart && options.chart.renderTo) || this,
            backgroundColor: null,
            borderWidth: 0,
            type: 'bar',
            margin: [2, 0, 2, 0],
            width: 120,
            height: 20,
            style: {
              overflow: 'visible'
            },

            // small optimalization, saves 1-2 ms each sparkline
            skipClone: true
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
          },
          yAxis: {
            endOnTick: false,
            startOnTick: false,
            labels: {
              enabled: false
            },
            title: {
              text: null
            },
            tickPositions: [0]
          },
          legend: {
            enabled: false
          },
          tooltip: {
            hideDelay: 0,
            outside: true,
            shared: true
          },
          plotOptions: {
            series: {
              stacking: 'normal',
              animation: false,
              lineWidth: 1,
              shadow: false,
              states: {
                hover: {
                  lineWidth: 1
                }
              },
              marker: {
                radius: 1,
                states: {
                  hover: {
                    radius: 2
                  }
                }
              },
              fillOpacity: 0.25
            },
            column: {
              negativeColor: '#910000',
              borderColor: 'silver'
            }
          }
        };

    options = Highcharts.merge(defaultOptions, options);

    return hasRenderToArg ?
        new Highcharts.Chart(a, options, c) :
        new Highcharts.Chart(options, b);
  };

  let start = +new Date(),
      $tds = $('td[data-sparkline]'),
      fullLen = $tds.length,
      n = 0;

  // Creating 153 sparkline charts is quite fast in modern browsers, but IE8 and mobile
  // can take some seconds, so we split the input into chunks and apply them in timeouts
  // in order avoid locking up the browser process and allow interaction.
  function doChunk() {
    let time = +new Date(),
        i,
        j,
        len = $tds.length,
        $td,
        arr,
        series,
        chart;

    for (i = 0; i < len; i += 1) {
      $td = $($tds[i]);

      arr = $td.data('sparkline');
      series = [];
      chart = {};

      for(j = 0; j < arr.length; j++) {
        let series_arr = arr[j];

        series.push({
          name: `${series_arr['name']}`,
          data: $.map(series_arr['data'], parseFloat),
          color: `${series_arr['color']}`,
          pointStart: 1
        });
      }

      $td.highcharts('SparkLine', {
        series: series,
        tooltip: {
          headerFormat: '<span style="font-size: 10px">' + $td.parent().find('td').html() + ':</span><br/>',
          //pointFormat: '<b>{point.y}</b>'
        },
        chart: chart
      });

      n += 1;

      // If the process takes too much time, run a timeout to allow interaction with the browser
      if (new Date() - time > 500) {
        $tds.splice(0, i + 1);
        setTimeout(doChunk, 0);
        break;
      }

      // Print a feedback on the performance
      //if (n === fullLen) {
      //  $('#result').html('Generated ' + fullLen + ' sparklines in ' + (new Date() - start) + ' ms');
      //}
    }
  }
  doChunk();

});
