import $ from 'jquery';
import 'rails-ujs';
import 'chosen-js';
import 'chosen-js/chosen.css';

document.addEventListener('turbolinks:before-cache', () => { destroy() });
document.addEventListener('notification-dropdown:destroy', () => { destroy() });

document.addEventListener('turbolinks:load', () => { initialize() });
document.addEventListener('notification-dropdown:initialize', () => { initialize() });

function destroy() {
  $('.notification-user-dropdown .chosen-select').chosen('destroy');
  $('.config-input.chosen-select').chosen('destroy');
}

function initialize() {
  $('.config-input.chosen-select').chosen();
  $('.notification-user-dropdown .chosen-select').chosen({
    multiple: true,
    search_contains: true,
    hide_results_on_select: false,
    prevent_hide: false
  });

  $('.notification-user-dropdown .chosen-container').on('click', function () {
    if ($('.notification-user-dropdown .chosen-button-wrapper').length === 0) {
      $(this).find('.chosen-search-input').val('');
      $('.notification-user-dropdown .chosen-drop').append('<div class="chosen-button-wrapper"><a class="btn btn-primary done-button" href = "javascript:void(0)"> Done </a><a class="btn btn-primary clear-button" href = "javascript:void(0)"> Clear </a></div>');

      $('.clear-button').on('click', function () {
        const $select = $(this).closest('.notification-user-dropdown').find('.chosen-select');
        $('option', $select).prop('selected', false).trigger('chosen:updated');
        $select.trigger('chosen:close');
      });

      $('.done-button').on('click', function () {
        $(this).closest('.notification-user-dropdown').find('.chosen-select').trigger('chosen:close');
      });
    }
  });

  $(document).on("mouseup", '.result-selected', function (event) {
    var dropdown = $(this).closest('.notification-user-dropdown');
    dropdown.find(".search-choice-close[data-option-array-index=" + $(this).data().optionArrayIndex + "]").trigger('click');
    dropdown.find('.chosen-search-input').trigger('click');
    event.preventDefault();
    event.stopPropagation();
  });
}
