/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";
import "@stimulus/polyfills"
import "src/custom_event"

import "src/rails"
import "src/external_sdks"
import "src/dht_anchor_offset"
import "src/bs3";
import 'src/property_filter';

import 'src/font_awesome'

import "src/dropdown"

// Necessary for jQuery UI, specifically spinner
import Globalize from "globalize";
window["Globalize"] = Globalize;

import "chartist";
import "chartist-plugin-legend";
import "chartist-plugin-pointlabels";
import "chartist-plugin-tooltips";
import "highcharts"

import 'src/widgets'

import "src/comments";
import "src/invitations";
import "src/expenses"
import "src/stickyfill";
import "src/occupancy_graph";
import "src/generic_line_graph";
import "src/egi_graph";
import "src/expiring_graph";
import "src/twelve_trend";
import "src/occupancy_trend_graph";
import "src/rents_achieved_graph";
import "src/notification_event";
import "src/slicer_filter";
import "../extensions/bootstrap-table.min";
import "../extensions/bootstrap-table-fixed-columns.min";
import "src/leaderboard_table";
import "src/stacked_bar_graph";
import "src/highchart";
import "src/highcharts-sparklines"
import "src/rollup_switcher";
import "src/edit_slicers";
import "src/variance_notes";



import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import 'stylesheets/bs4-upgrade'

const application = Application.start();
const context = require.context("controllers", true, /.?s$/);
application.load(definitionsFromContext(context));

require("trix")
require("@rails/actiontext")

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});
